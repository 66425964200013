import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import Icon from '../../../components/Icon';
import Step from './components/Step';
import { RotateImg } from './components/Rotate';
import { Card1, Card2, Card3, Card4, Card5, Card6, Card7, Card8 } from './components/Card';
import * as styles from './index.module.less';

const Index = () => {
  const StepList = [
    {
      key: 0,
      title: '毛坯交易',
      time: '交易时间: 2019-10-01',
      iconType: 'iconmaopijiaoyi',
      data: <Card1 />,
    }, {
      key: 1,
      title: '毛坯加工',
      time: '加工完成: 2019-10-10',
      iconType: 'iconmaopijiagong',
      data: <Card2 />,
    }, {
      key: 2,
      title: 'GIA出具证书',
      time: '出证时间: 2019-10-18',
      iconType: 'icongia',
      data: <Card3 />,
    }, {
      key: 3,
      title: '钻交所报关',
      time: '申报时间: 2019-12-01',
      iconType: 'iconhaiguan',
      data: <Card4 />,
    }, {
      key: 4,
      title: '零售商交易',
      time: '下单时间: 2019-12-20',
      iconType: 'iconlingshoushang',
      data: <Card5 />,
    }, {
      key: 5,
      title: '工厂成品镶嵌',
      time: '镶嵌完成: 2020-01-03',
      iconType: 'iconxiangqian',
      data: <Card6 />,
    }, {
      key: 6,
      title: 'NGTC成品证书',
      time: '出证时间: 2020-01-20',
      iconType: 'iconngtc',
      data: <Card7 />,
    }, {
      key: 7,
      title: '消费者',
      time: '购买时间: 2020-10-20',
      iconType: 'iconFrame',
      data: <Card8 />,
    }
  ]

  useEffect(() => {
    document.title = "珠宝区块链证书";
  }, []);
  return (
    <div className={styles.contant}>
      <div className={styles.top}>
        {/*<Rotate />*/}
        <RotateImg />
        <div className={styles.tag}>
          <span>
            <Icon type="iconfangcuangai" className={styles.icon} />
            防篡改
          </span>
          <span>
            <Icon type="iconkezhuisu" className={styles.icon} />
            可追溯
          </span>
          <span>
            <Icon type="icontouming" className={styles.icon} />
            透明
          </span>
        </div>
        <div onClick={() => { navigate('/h5/blockchain/certificate') }} className={styles.btn}>
          <span>区块链标识：WB2020299392929</span>
          <Icon type="iconRight" className={styles.iconRight} />
        </div>
      </div>
      <div className={styles.bottom}>
        <Step list={StepList} />
      </div>
    </div>
  )
}

export default Index;